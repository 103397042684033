/* Load boostrap calendar theme that blends OK with the App theme
 * Without having to install all the bootstrap packages */
@import 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.min.css';
@import 'https://cdn.jsdelivr.net/npm/bootswatch@5.2.0/dist/lux/bootstrap.min.css';

/* Reset bootstrap stuff */

body, .btn {
    font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: normal;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: capitalize;
    letter-spacing: normal;
}

:root {
    /* Variables are defined with a default value and should be updated by JS and the React theme */
    --fcToolbarBtnColor: rgb(64, 175, 237);
    --fc-today-bg-color: rgba(255, 255, 255, 0.15);
}

.fc div,
.fc h2 {
    color: #FFFFFF;
}

.fc-toolbar .btn,
.fc-toolbar .btn.active,
.fc-toolbar .btn:focus,
.fc-toolbar .btn.active:focus,
.fc-toolbar .btn:hover {
    font-size: 16px;
    font-weight: lighter;
    color: var(--fcToolbarBtnColor);
    background: none;
    border: none;
    box-shadow: none;
}

.fc-toolbar button.btn.active, .fc-toolbar button.btn.active:focus {
    font-weight: bold;
}
