html, body {
  background-color: #0E102F;
}

* *::-webkit-scrollbar {
  width: 10px;
}

* *::-webkit-scrollbar-thumb {
  background-color: #384178;
  border-radius: 4px;
}