.cs-message-list,
.cs-message-input .cs-message-input--disabled {
  background-color: #252B50;
}
.cs-chat-container .cs-message-input {
  border-color: #384178;
  background-color: #252B50;
}
.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor {
  background-color: #3a447e;
  color: #FFFFFF;
}
.cs-message-input__content-editor::before {
  color: #AAAAAA !important;
}
.cs-message--outgoing .cs-message__content {
  background-color: #384178;
  border-radius: 0.7em;
  color: #FFFFFF !important;
}
.cs-message--incoming .cs-message__content {
  background-color: #121628;
  border-radius: 0.7em;
  color: #FFFFFF !important;
}
.cs-message-input__tools {
  background-color: #252B50;
  color: #384178;
}
.cs-button--send {
  color: #778aff;
}
.cs-message__sent-time {
  font-family: sans-serif;
  font-size: 10px;
  color: #FFFFFF !important;
}
.cs-message__sender-name {
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF !important;
}
